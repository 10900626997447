<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 2em; padding-top: 2em;") CÓMO UTILIZARLO
          p(style="margin-bottom: 3em;") #[strong Si nuestro IPJ es superior a nuestro IPS]  significa que podríamos subir algo los precios, en ningún caso se recomienda de una sola vez igualar el IPS al IPJ, si no que hay que hacerlo en caso que se quiera con pequeños ajustes al alza y de forma muy quirúrgica escogiendo aquellos productos de nuestra oferta que en comparación con los de la competencia  puedan tener margen de incremento.
          p Por el contrario #[strong si nuestro IPJ es inferior a nuestro IPS], deberemos asumir que los clientes consideran que nuestra relación Calidad /precio esta por debajo de la media del mercado y deberíamos o ajustar precios a la baja (no recomendable por su impacto en rentabilidad) o aumentar la percepción de calidad de la experiencia de los clientes.



  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 2em; padding-top: 2em;") HOW TO USE IT
          p(style="margin-bottom: 3em;") #[strong If our API is higher than our SACI], it means that we could raise prices somewhat. It's not recommended to equalize the SAVI to the API at one time. Instead, it must be done with minor upward adjustments and in a way very surgical, choosing those products of our offer that in comparison with those of the competition can have a margin of increase.
          p On the contrary, #[strong if our API is lower than our SACI], we must assume that customers consider that our quality/price ratio is below the market average. We should either adjust prices downwards (not recommended due to its impact on profitability) or increase the perception of the quality of the customer experience.

</template>

<script>
export default {
  name: "Card_2",
  components: {}
}
</script>

<style scoped>

</style>
